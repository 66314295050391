<template>
    <div>
       <div class="bg">
            <img src="@/assets/img/index_title.png" class="bg_title" alt="">
            <div class="map">
                <img src="@/assets/img/map_wendeng.png" class="map_icon map_wendeng" @click="openDetail(0)" alt="">
                <img src="@/assets/img/map_penglai.png" class="map_icon map_penglai" @click="openDetail(1)" alt="">
                <img src="@/assets/img/map_fushan.png" class="map_icon map_fushan" @click="openDetail(2)" alt="">
                <img src="@/assets/img/map_wandi.png" class="map_icon map_wandi" @click="openDetail(3)" alt="">
                <img src="@/assets/img/map_zhaoyuan.png" class="map_icon map_zhaoyuan" @click="openDetail(4)" alt="">
                <img src="@/assets/img/map_laizhou.png" class="map_icon map_laizhou" @click="openDetail(5)" alt="">
                <img src="@/assets/img/map_laixi.png" class="map_icon map_laixi" @click="openDetail(6)" alt="">
                <img src="@/assets/img/map_pingdu.png" class="map_icon map_pingdu" @click="openDetail(7)" alt="">
                <img src="@/assets/img/map_jimo.png" class="map_icon map_jimo" @click="openDetail(8)" alt="">
                <img src="@/assets/img/map_jiaozhou.png" class="map_icon map_jiaozhou" @click="openDetail(9)" alt="">
                <img src="@/assets/img/map_gaominan.png" class="map_icon map_gaominan" @click="openDetail(10)" alt="">
                <img src="@/assets/img/map_zhucheng.png" class="map_icon map_zhucheng" @click="openDetail(11)" alt="">
                <img src="@/assets/img/map_wulianbei.png" class="map_icon map_wulianbei" @click="openDetail(12)" alt="">
                <img src="@/assets/img/map_rizhao.png" class="map_icon map_rizhao" @click="openDetail(13)" alt="">
                <img src="@/assets/img/map_lingang.png" class="map_icon map_lingang" @click="openDetail(14)" alt="">
                <img src="@/assets/img/map_lvxian.png" class="map_icon map_lvxian" @click="openDetail(15)" alt="">
                <img src="@/assets/img/map_lvnan.png" class="map_icon map_lvnan" @click="openDetail(16)" alt="">
                <img src="@/assets/img/map_linyinan.png" class="map_icon map_linyinan" @click="openDetail(17)" alt="">
                <img src="@/assets/img/map_tancheng.png" class="map_icon map_tancheng" @click="openDetail(18)" alt="">
                <img src="@/assets/img/map_fangan.png" class="map_icon map_fangan" @click="openDetail(19)" alt="">
                <img src="@/assets/img/map_weifangbei.png" class="map_icon map_weifangbei" @click="openDetail(20)" alt="">
                <img src="@/assets/img/map_weifangxi.png" class="map_icon map_weifangxi" @click="openDetail(21)" alt="">
                <img src="@/assets/img/map_changlenan.png" class="map_icon map_changlenan" @click="openDetail(22)" alt="">
                <img src="@/assets/img/map_shouguang.png" class="map_icon map_shouguang" @click="openDetail(23)" alt="">
                <img src="@/assets/img/map_guangrao.png" class="map_icon map_guangrao" @click="openDetail(24)" alt="">
                <img src="@/assets/img/map_qingzhou.png" class="map_icon map_qingzhou" @click="openDetail(25)" alt="">
                <img src="@/assets/img/map_zibo.png" class="map_icon map_zibo" @click="openDetail(26)" alt="">
                <img src="@/assets/img/map_linqu.png" class="map_icon map_linqu" @click="openDetail(27)" alt="">
                <img src="@/assets/img/map_botou.png" class="map_icon map_botou" @click="openDetail(28)" alt="">
                <img src="@/assets/img/map_wudi.png" class="map_icon map_wudi" @click="openDetail(29)" alt="">
                <img src="@/assets/img/map_binzhou.png" class="map_icon map_binzhou" @click="openDetail(30)" alt="">
                <img src="@/assets/img/map_zouping.png" class="map_icon map_zouping" @click="openDetail(31)" alt="">
                <img src="@/assets/img/map_wenchanghu.png" class="map_icon map_wenchanghu" @click="openDetail(32)" alt="">
                <img src="@/assets/img/map_yiyuan.png" class="map_icon map_yiyuan" @click="openDetail(33)" alt="">
                <img src="@/assets/img/map_yishui.png" class="map_icon map_yishui" @click="openDetail(34)" alt="">
                <img src="@/assets/img/map_laiwu.png" class="map_icon map_laiwu" @click="openDetail(35)" alt="">
                <img src="@/assets/img/map_jinandong.png" class="map_icon map_jinandong" @click="openDetail(36)" alt="">
                <img src="@/assets/img/map_jinan.png" class="map_icon map_jinan" @click="openDetail(37)" alt="">
                <img src="@/assets/img/map_dezhoudong.png" class="map_icon map_dezhoudong" @click="openDetail(38)" alt="">
                <img src="@/assets/img/map_dezhou.png" class="map_icon map_dezhou" @click="openDetail(39)" alt="">
                <img src="@/assets/img/map_pingyuan.png" class="map_icon map_pingyuan" @click="openDetail(40)" alt="">
                <img src="@/assets/img/map_gaotang.png" class="map_icon map_gaotang" @click="openDetail(41)" alt="">
                <img src="@/assets/img/map_xiajin.png" class="map_icon map_xiajin" @click="openDetail(42)" alt="">
                <img src="@/assets/img/map_qihe.png" class="map_icon map_qihe" @click="openDetail(43)" alt="">
                <img src="@/assets/img/map_liaocheng.png" class="map_icon map_liaocheng" @click="openDetail(44)" alt="">
                <img src="@/assets/img/map_guanxian.png" class="map_icon map_guanxian" @click="openDetail(45)" alt="">
                <img src="@/assets/img/map_pingyin.png" class="map_icon map_pingyin" @click="openDetail(46)" alt="">
                <img src="@/assets/img/map_taian.png" class="map_icon map_taian" @click="openDetail(47)" alt="">
                <img src="@/assets/img/map_taishan.png" class="map_icon map_taishan" @click="openDetail(48)" alt="">
                <img src="@/assets/img/map_xintaixi.png" class="map_icon map_xintaixi" @click="openDetail(49)" alt="">
                <img src="@/assets/img/map_dongpingnan.png" class="map_icon map_dongpingnan" @click="openDetail(50)" alt="">
                <img src="@/assets/img/map_yanzheng.png" class="map_icon map_yanzheng" @click="openDetail(51)" alt="">
                <img src="@/assets/img/map_yuncheng.png" class="map_icon map_yuncheng" @click="openDetail(52)" alt="">
                <img src="@/assets/img/map_heze.png" class="map_icon map_heze" @click="openDetail(53)" alt="">
                <img src="@/assets/img/map_caozhou.png" class="map_icon map_caozhou" @click="openDetail(54)" alt="">
                <img src="@/assets/img/map_dongchangfu.png" class="map_icon map_dongchangfu" @click="openDetail(55)" alt="">
                <img src="@/assets/img/map_liangshan.png" class="map_icon map_liangshan" @click="openDetail(56)" alt="">
                <img src="@/assets/img/map_jining.png" class="map_icon map_jining" @click="openDetail(57)" alt="">
                <img src="@/assets/img/map_qufu.png" class="map_icon map_qufu" @click="openDetail(58)" alt="">
                <img src="@/assets/img/map_tengzhou.png" class="map_icon map_tengzhou" @click="openDetail(59)" alt="">
                <img src="@/assets/img/map_zaozhuang.png" class="map_icon map_zaozhuang" @click="openDetail(60)" alt="">
                <img src="@/assets/img/map_xuecheng.png" class="map_icon map_xuecheng" @click="openDetail(61)" alt="">
                <img src="@/assets/img/map_zaozhuangdong.png" class="map_icon map_zaozhuangdong" @click="openDetail(62)" alt="">
                <img src="@/assets/img/map_mengyin.png" class="map_icon map_mengyin" @click="openDetail(63)" alt="">
                <img src="@/assets/img/map_mengshan.png" class="map_icon map_mengshan" @click="openDetail(64)" alt="">
                <img src="@/assets/img/map_feixian.png" class="map_icon map_feixian" @click="openDetail(65)" alt="">
                <img src="@/assets/img/map_yinan.png" class="map_icon map_yinan" @click="openDetail(66)" alt="">
                <img src="@/assets/img/map_linyi.png" class="map_icon map_linyi" @click="openDetail(67)" alt="">
                <img src="@/assets/img/map_jinanbei.png" class="map_icon map_jinanbei" @click="openDetail(68)" alt="">
                <img src="@/assets/img/map_changqing.png" class="map_icon map_changqing" @click="openDetail(69)" alt="">
                <img src="@/assets/img/map_jinanjingtai.png" class="map_icon map_jinanjingtai" @click="openDetail(70)" alt="">
                <img src="@/assets/img/map_ningyang.png" class="map_icon map_ningyang" @click="openDetail(71)" alt="">
                <img src="@/assets/img/map_haiyang.png" class="map_icon map_haiyang" @click="openDetail(72)" alt="">
                <img src="@/assets/img/map.png" class="map_img" alt="">
            </div>
            <img src="@/assets/img/bg.png" class="bgimg" alt="">
       </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
    return {
        
    };
  },
  methods:{
    openDetail(index){
        this.$router.push({path:'/detail',query:{id:index}})
    }
  }
}
</script>

<style>

</style>