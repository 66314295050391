// 引入Vue核心库
import Vue from 'vue'
// 引入vue-router
import VueRouter from 'vue-router'
// 应用vue-router插件
Vue.use(VueRouter)
 
// 引入组件（方式一）
import Home from '../views/index.vue'
import Detail from '../views/detail.vue'
 
// 配置路由
const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/detail',
    component: Detail
  }
]
 
// 创建路由器
const router = new VueRouter({
  routes
})
 
// 导出路由器
export default router