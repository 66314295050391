var param = [
    {
        name:'文登',
        title:'威青高速文登服务区',
        imgUrl:require('@/assets/img/img_1_1.png'),
        list:[
            {
                title:'花饽饽',
                img:require('@/assets/img/img_1_2.png'),
                content:'登花饽饽：在文登，蒸花饽饽的习俗由来以久，经过数百年的传承，由简单的大枣饽饽演变为龙、虎、花、鸟、虫等形状不同的花饽饽，兼具食用性和欣赏性。如今“文登花饽饽”已成为一张宣传文登的城市名片，并多次被评选为省市级优秀旅游商品。2013年10月，文登花饽饽被作为贺礼赠送给台湾熊猫宝宝。'
            }
        ]
    },
    {
        name:'蓬莱',
        title:'蓬莱服务区',
        imgUrl:require('@/assets/img/img_2_1.png'),
        list:[
            {
                title:'蓬莱小面',
                img:require('@/assets/img/img_2_2.png'),
                content:'蓬莱小面是中国山东省烟台市的一道传统美食。据传，蓬莱小面有两千多年的历史。早在魏晋时期，人们就开始将制作面条的方法传承下来。而在唐宋时期，蓬莱小面已经日趋成熟，成为了一道鲜美的传统美食。<br/>与其他地方的面食不同，蓬莱小面的特点在于其细、长、柔软而有弹性。它的原料有面粉、水和碘盐，其中最重要的是面粉。面粉的品质决定了蓬莱小面的好坏，只有选用优质的泉水小麦面粉才能制作出口感细嫩、爽滑如丝的好面条。另外，蓬莱小面是以手工拉制而成，需要经历多道工序和反复捶打、抖面、折叠等手工技艺，才能保证面条的吸水性、耐煮性和口感。因此，制作蓬莱小面需要高度的耐心和技艺。<br/>在蓬莱，制作好的小面不仅可以现场卖面，也可以打包带走。几乎每个美食爱好者都会选择带上几袋小面回家，或者作为伴手礼送给朋友。蓬莱小面的美味让很多游客一尝难忘，远在他乡时，总是怀念蓬莱小面的口感。'
            }
        ]
    },
    {
        name:'福山',
        title:'福山服务区',
        imgUrl:require('@/assets/img/img_3_1.png'),
        list:[
            {
                title:'烟台焖子',
                img:require('@/assets/img/img_3_2.png'),
                content:'烟台焖子是山东省烟台市的一道传统小吃，属于鲁菜系，该菜品用粗制地瓜淀粉做原料，将淀粉加水加热打冻，将冻切成小块，用锅煎到外边成焦状颜色由白变透明，并佐以虾油，芝麻酱，蒜汁等调料上桌即可。<br/>弹糯的口感，一层的脆疙，再淋上蒜汁、芝麻酱，从老到少全都爱。就是那一层嚼着超过瘾的疙，才是焖子的灵魂原本灰中透白的焖子。经过油煎、焖制，外面裹上了一层薄薄的焦黄锅巴，但是里面却依然是软软糯糯的。也许是由于浇上了虾油的缘故，吃上去口感筋道又绵密，要是个重口味的，就再淋上点辣椒油，鲜香、麻辣浑然。明明是一盘健康素食，但口味愣是香过肉冻，赛过牛蹄筋，这滋味真是“绝了”!'
            }
        ]
    },
    {
        name:'万第',
        title:'万第服务区',
        imgUrl:require('@/assets/img/img_4_1.png'),
        list:[
            {
                title:'海鲜芋头',
                img:require('@/assets/img/img_4_2.png'),
                content:'俗话说“秋天吃芋头，年年有余头”。莱阳市万第镇盛产芋头，在古代芋头对百姓而言是重要的主食，汉代以后芋头便逐渐退出了主食行列，转向了蔬菜支线。芋头搭配当地特产海鲜，无论是新鲜虾酱，还是鱼虾蟹蚌，再加上地瓜粉、蒜泥、葱段、彩椒、盐、味精、糖、料酒、香油、蚝油等丰富的佐料，便是餐桌上的美食，可谓鲜上加鲜，令人回味无穷。'
            }
        ]
    },
    {
        name:'招远',
        title:'龙青高速招远服务区',
        imgUrl:require('@/assets/img/img_5_1.png'),
        list:[
            {
                title:'招远蒸丸',
                img:require('@/assets/img/img_5_2.png'),
                content:'招远蒸丸已有百余年历史，主要流行于招远北部乡村。招远蒸丸的主料是猪肉，比例为肥、瘦各半。做法是瘦肉剁成泥，肥肉切薄片，两面剞直刀，成筷子头大小的丁。辅料有鸡蛋、白菜心、葱、香菜及精盐、味精、胡椒粉等调料。与其他丸子的辅料不同的是，招远蒸丸的辅料中一定要有两种海味：鹿角菜（一种状似鹿角的海藻）和海米，大概主要是为了解腻和口感。除了瘦肉成茸，肥肉切丁外，其余辅料均切碎剁细，加调味品后放入容器内，顺一个方向搅合，以便使丸子上浆、有劲。然后挤成核桃大小的丸子码放盘中，入锅，大火蒸10分钟左右即可。浇上用精盐、味精、食醋、胡椒面、香油调对的清汤即可。成品汤浓味鲜，酸辣咸香，质地疏松软烂，肥而不腻。'
            }
        ]
    },
    {
        name:'莱州',
        title:'莱州服务区',
        imgUrl:require('@/assets/img/img_6_1.png'),
        list:[
            {
                title:'鲅鱼饼子',
                img:require('@/assets/img/img_6_2.png'),
                content:'鲅鱼饼子是一道具有浓厚海味的传统美食。这道菜的主要原料是新鲜的鲅鱼肉，再搭配上适量的葱姜蒜等调料，制作而成。鮮嫩的鲅鱼肉经过剁碎并和调料混合均匀后，用手巧妙地搓成小饼状。<br/>鲅鱼饼子的外观金黄诱人，气味香浓扑鼻，煎熟后鲜嫩多汁。咬上一口，口感鲜美，肉质细腻鲜嫩，入口即化。香浓的鲅鱼肉与葱姜蒜调料的完美搭配，让人回味无穷。这道菜既可当作主菜享用，也可作为美味的小吃。鲅鱼饼子常常出现在家庭聚会和酒席上，受到人们的喜爱。只需煎炸数分钟，外酥里嫩的鲅鱼饼子即可诱人上桌。总而言之，鲅鱼饼子以其口感鲜美、香味扑鼻、营养丰富的特点，成为了人们餐桌上的一道美味佳肴。无论是家庭聚餐还是酒席宴请，这道传统美食都能让人们享受到纯正的海鲜美味。'
            }
        ]
    },
    {
        name:'莱西',
        title:'莱西服务区',
        imgUrl:require('@/assets/img/img_7_1.png'),
        list:[
            {
                title:'红焖江团鱼',
                img:require('@/assets/img/img_7_2.png'),
                content:'选用新鲜江团鱼及时令蔬菜，荤素搭配，营养均衡，将鱼改刀成能快速入味又使鱼肉不容易散的程度，成菜色泽红亮，香味浓郁。将团鱼宰杀后用热水淋，去膜后开壳宰净，切成方形块，拌入豉油，干薯粉，起锅，放入少量油，然后落香菇、蒜仁、姜片炒时后，再下水鱼生炒，加入200克汤水，调味，转入慢火焖软盛盘。'
            }
        ]
    },
    {
        name:'平度',
        title:'平度服务区',
        imgUrl:require('@/assets/img/img_8_1.png'),
        list:[
            {
                title:'崮山芥末鸡',
                img:require('@/assets/img/img_8_2.png'),
                content:'芥末鸡是山东地区的一道传统名菜，用鸡和芥末等调叶品调制的一种凉拌菜。它的名气可与朝天锅、城隍庙火烧相媲美，吃过芥末鸡的人都会对这道名不见经传的芥末鸡赞不绝口。它细嫩醇香，咸鲜香辣，清爽通窍。'
            }
        ]
    },
    {
        name:'即墨',
        title:'即墨服务区',
        imgUrl:require('@/assets/img/img_9_1.png'),
        list:[
            {
                title:' 大虾烧白菜',
                img:require('@/assets/img/img_9_2.png'),
                content:" 因青岛多海鲜，所以这道菜连一般的家庭都很擅长做。虾的鲜味和白菜结合在一起味道纯美。用虾数只，剪掉腿、头前边的硬刺等等一切可能会妨碍你吃它的部分，用热水焯一下外皮泛红就捞起来备用；白菜叶，用手撕成大小合适的片，油热了之后，放入虾，记得用铲子压一压虾的头部，这样能让虾的脑油流出，可以看到油变红了，然后放入白菜翻炒，等炒出一定水分的时候，放入盐和糖就好了。"
            }
        ]
    },
    {
        name:'胶州',
        title:'胶州服务区',
        imgUrl:require('@/assets/img/img_10_1.png'),
        list:[
            {
                title:'海鲜烩饼',
                img:require('@/assets/img/img_10_2.png'),
                content:"有一种美味--海鲜烩饼，料多量足、鲜美可口，是青岛的一个特色小吃。有各式各样的海鲜食材，花蛤、大虾、乌子、籽乌、鲍鱼……新鲜的乌子Q弹有嚼劲，搭配上爽口的小油菜或小香菜，吃起来有韧性的烩饼被切成细小长条泡在海鲜汤里，配上海鲜特有的鲜味，一口下去，咸香口感充斥着口腔，味道十分鲜美。"
            }
        ]
    },
    {
        name:'高密',
        title:'高密南服务区',
        imgUrl:require('@/assets/img/img_11_1.png'),
        list:[
            {
                title:'高密炉包',
                img:require('@/assets/img/img_11_2.png'),
                content:"民间传统名吃高密炉包历史悠久，风靡城乡。始于清代年间，在民国时期有着独特意义的形势盛行，用来走亲访友的礼物送给朋友，特别是20世纪30年代位于县城南河湾的林三炉包，更是名冠城乡。其特点是包子又白又大，皮面暄软，内陷饱满，味道鲜美，挂浆讲究，火候独到，嘎渣焦脆，油香四溢。高密炉包用料讲究，以鲜猪肉、鲜韭菜、鲜白菜和上好的精面粉为主料，以海米、木耳及各种调料为辅料，精心加工而成。解放后，随着人民生活水平的提高，高密炉包已遍及城乡，成为高密人民的大众食品。"
            }
        ]
    },
    {
        name:'诸城',
        title:'诸城服务区',
        imgUrl:require('@/assets/img/img_12_1.png'),
        list:[
            {
                title:'诸城烧肉',
                img:require('@/assets/img/img_12_2.png'),
                content:"诸城烧肉是名扬山东的美食一绝。新出锅的烧肉棕黄油亮，香气四溢，吃起来肥而不腻，瘦而不柴，糯而酥烂，香而爽口，齿颊留芳，回味无穷。<br/>诸城烧肉“后宫众粉黛，养颜有秘方。御宴多名菜，唯摄烧烤扒。”据载，宋朝大学士苏东坡从汴京贬至密州时，宋神宗赵顼因赏识其诗文，便派了一名御厨随从，名始为照顾生活实为早得其词，“烧烤”御宴秘方便从此传至诸城。1997年被山东省贸易厅评为“山东名小吃”。"
            }
        ]
    },
    {
        name:'五莲北',
        title:'五莲北服务区',
        imgUrl:require('@/assets/img/img_13_1.png'),
        list:[
            {
                title:'许孟炉包',
                img:require('@/assets/img/img_13_2.png'),
                content:"许孟炉包有着悠久的历史渊源，馅好、面细、皮佳、火候巧，醇香宜口，餐后回味无穷，素有美食之称，颇得人们青睐，闻名四面八方。被当地人誉为炉包一绝，一直传承至今。这种包子的中间口不封实，初下锅油煎时，口先朝下，一锅可以放几十个包子，然后加水、撒面粉。由于包子留着口，先朝下的话，包子中馅儿料里的汁儿和锅里的水能够相互交融，使得包子皮儿也能吸收到馅儿的香味。最后，锅中还要洒入面粉水，在煎的过程中，水分蒸发后，一个个包子外皮会形成薄薄的酥皮，将包子连在一起，最后形成包子间的那层酥脆的锅巴，也就是人们所称的“嘎渣”。许孟的炉包，在煎的时候，通常要给包子翻两次身，这样煎出的包子，正反两面都呈金黄色，吃起来更加外脆里嫩。"
            }
        ]
    },
    {
        name:'日照',
        title:'日照服务区',
        imgUrl:require('@/assets/img/img_14_1.png'),
        list:[
            {
                title:'鱼锅片片',
                img:require('@/assets/img/img_14_2.png'),
                content:"以小海鱼、玉米面、黄豆面为主料制作的菜品，是山东省胶东地区的一道特色传统名菜。鱼锅似乎很好理解，鱼在锅里炖着便可称鱼锅；片片乃胶东方言，即用玉米面为主加工而成的一种扁平状主食，也有的地方叫作饼子。"
            }
        ]
    },
    {
        name:'临港',
        title:'临港服务区',
        imgUrl:require('@/assets/img/img_15_1.png'),
        list:[
            {
                title:'乔家牛肉',
                img:require('@/assets/img/img_15_2.png'),
                content:"乔家牛肉，是团林镇知名品牌，历史悠久，风味独特。“乔家牛肉”之所以有如此大的魅力，是与其精工制作、味道鲜美有着密不可分的关系。在烹制前将牛肚绷切成约3厘米见方的块状，在清水中浸泡，待血水泡净后，捞出用麻油浸拌一段时间后，再放放锅内用中火煮沸后用文火慢煨，在肉块烂时放入佐料，肉烂后即可食用。肉块清爽，入口易嚼而又不失劲道；汤汁浓厚，口味甘醇，饮后齿颊留香。食客品尝以后，都要翘起大拇指，赞不绝口，称之为人间美味，不可多得。"
            }
        ]
    },
    {
        name:'莒县',
        title:'莒县服务区',
        imgUrl:require('@/assets/img/img_16_1.png'),
        list:[
            {
                title:'莒县全羊汤',
                img:require('@/assets/img/img_16_2.png'),
                content:"莒县全羊 (又名莒州全羊 )，伴随莒国悠久的历史，承载着“姑幕”丰厚的文化底蕴，莒县全羊汤犹如渗透进商周秦汉的古老文明与现代人文的生机和谐。那缕缕飘香的“玉液”，闻之心旷神怡，饮之七窍通达，不禁令人生发“天上人间之美味，尽在苔县全羊汤”之感慨! 其制作很有讲究，选材必须是莒县当地散养的 12 个月内的小山羊，羊肉、羊杂在冷水中浸泡 2小时去血水，放在煮羊骨的大锅中煮至断生，羊骨要敲碎煮 1 小时以上出白汤为止。吃时，将切过的羊肉、羊杂放在碗中浇入滚开的羊汤调上盐、味精、醋、胡椒粉、韭花等，在配上莒县的大饼，喝上一碗甚是过瘾。"
            }
        ]
    },
    {
        name:'莒南',
        title:'莒南服务区',
        imgUrl:require('@/assets/img/img_17_1.png'),
        list:[
            {
                title:'莒南驴肉',
                img:require('@/assets/img/img_17_2.png'),
                content:"莒南驴肉是山东省临沂市莒南县地方传统名吃，属鲁菜系。<br/>莒南驴肉味道鲜美，营养丰富，在驴肉的烹制上有独到的功夫，制作的熟驴肉奇香，肉汤奇鲜，深受食客称赞。莒南驴肉，色、香、味、形具佳，是大补佳品，与临沂八宝豆豉，蒙阴光棍鸡并列为沂蒙三大名吃。<br/>天上的龙肉，地上的驴肉，莒南毛驴纯饲青草等无公害绿色植物，饮天湖、石泉湖天然矿泉水，其肉味道鲜美，营养丰富，闻名全国的“莒南老地方驴肉店”，用独特工艺炮制的香喷喷的驴肉，配以色泽澄黄汁汤，辅之浓香咸辣的佐料，令人百吃不厌。"
            }
        ]
    },
    {
        name:'临沂南',
        title:'临沂南服务区',
        imgUrl:require('@/assets/img/img_18_1.png'),
        list:[
            {
                title:'特色酱驴肉',
                img:require('@/assets/img/img_18_2.png'),
                content:"古就有这么一种说法：天上龙肉，地上驴肉，可见人们对驴肉之美味的喜爱。在临沂市兰陵县（原苍山县）的东南方向，有一个乡镇“二庙”，这里还有人们最喜爱吃的驴肉，驴肉具有补血益气的功效，一般人均可食用，驴肉肉质鲜嫩可口，是老年人的滋补圣品，亦是久病初愈，气血两亏者的食疗佳品。二庙驴肉在北方非常的出名，很多都是几代人的传承，从收驴，杀驴，煮驴肉，卖驴肉形成了流水线作业，刚煮好的驴肉香嫩爽口，真是人间不可多得的美味佳肴，有时间快来这尝尝吧！"
            }
        ]
    },
    {
        name:'郯城',
        title:'郯城服务区',
        imgUrl:require('@/assets/img/img_19_1.png'),
        list:[
            {
                title:'马头黄鳝',
                img:require('@/assets/img/img_19_2.png'),
                content:"郯城马头黄鳝，地方名吃，现捕现做，主打一个新鲜，在注重色香味调配的前提下，遵循中医养生保健原理和现代营养学的有关原理，精选配料，经过几道工序烧制完成。广大食客在长期的体验和感受中将其总概括为：补气养血，美容养颜，香辣可口，肉质紧致，味道极佳”几大特点，使切身体会到“补气血最佳、”的功效，不同于其他名吃美味。黄鳝口味麻辣鲜、肉味独特、色泽诱人，素有“闻闻欲食，食之思味，味浓而美，百吃不厌”的赞誉，被评为“郯城地方名小吃”。"
            }
        ]
    },
    {
        name:'坊安',
        title:'坊安服务区',
        imgUrl:require('@/assets/img/img_20_1.png'),
        list:[
            {
                title:'潍坊肉火烧',
                img:require('@/assets/img/img_20_2.png'),
                content:"潍坊肉火烧是山东省潍坊市的一种特色小吃，潍坊本地人都叫老潍县肉火烧，潍坊的火烧品种很多，较为出名的有潍坊肉火烧、杠子头火烧、脂烙酥火烧。潍坊肉火烧把肉馅包进撕成的小面团里，收边做成扁圆形的火烧坯，再放进炉里，随着一次又一次的翻烤，猪肉里的肥油滋润了馅料，外皮酥脆、香而不腻深受当地居民所喜爱。"
            }
        ]
    },
    {
        name:'潍坊北',
        title:'潍坊北服务区',
        imgUrl:require('@/assets/img/img_21_1.png'),
        list:[
            {
                title:'萝卜丝饼',
                img:require('@/assets/img/img_21_2.png'),
                content:"做好的萝卜饼色泽金黄，外焦里嫩，鲜香可口，在本地极受欢迎，因为外香里嫩的特色，成为了许多地方很多人的早餐之一。萝卜饼也有非常高的营养价值可消食化痰，清热解毒，腹胀失音，消渴、痢疾、偏头痛等等，别疑难病症都是萝卜饼的食疗范围。"
            }
        ]
    },
    {
        name:'潍坊西',
        title:'潍坊西服务区',
        imgUrl:require('@/assets/img/img_22_1.png'),
        list:[
            {
                title:'朝天锅',
                img:require('@/assets/img/img_22_2.png'),
                content:"朝天锅是山东潍坊地区传统名吃，属鲁菜系。特色是以烙饼卷肉，食用时不需要锅具，起源于清代乾隆年间的民间早市，流传下来。潍坊朝天锅经过不断改进于1997年分别被中国烹饪协会、山东省贸易厅认定为“中华。<br/>朝天锅是用鸡肉、驴肉煨汤、以煮猪下货为主，有猪头、肝、肺、心、肚、肠，其中猪肠不要断的，并且要翻过来清洗。肠、口条、猪头肉要先用开水涮过，再放到老汤锅里煮，火候要掌握得恰到好处。猪下货煮熟后捞出切好备用,面饼擀好烙熟后也放到茅囤子中备用。所配小料也很讲究，葱段需切成长短一致的小段，咸菜疙瘩切好后再重新调汁腌制；鲜汤配以葱末、香菜末、醋、胡椒粉、辣椒面等小料  。朝天锅以“逢二排七大集间，白浪河畔人如山。寒流雪翻火正红，下水香锅面朝天”闻名，朝天锅肉肥而不腻，汤清淡而不浑浊，加以薄饼卷食，其味无穷 。"
            }
        ]
    },
    {
        name:'昌乐',
        title:'昌乐服务区',
        imgUrl:require('@/assets/img/img_23_1.png'),
        list:[
            {
                title:'马宋饼',
                img:require('@/assets/img/img_23_2.png'),
                content:"马宋饼是源于山东省昌乐县马宋(现为营丘镇)一道传统面食，故称之为马宋饼。至于起源于何时，由于历时年代久远，故无从考究。相传地处山东半岛中部的昌乐马宋，是清朝初期鲁东南士子进京赶考的必经之地。古时进京赶考之人多以步行，经过长途跋涉历时一个多月的时间才以到达目的地。由于马宋饼便于携带、存放时间长、耐消化之优点因此深受各方举人的喜爱。更有文人墨客作诗一首以表达对马宋饼的喜爱之情。“马宋油饼三页成，烙花细碎似繁星。油丰柔软食不腻。微咸当中透香气。熟而不糊色泽艳，垂手一抖即三片。开人胃口保体健，吃之过后皆称赞”。"
            }
        ]
    },
    {
        name:'寿光',
        title:'寿光服务区',
        imgUrl:require('@/assets/img/img_24_1.png'),
        list:[
            {
                title:'虎头鸡',
                img:require('@/assets/img/img_24_2.png'),
                content:"虎头鸡：虎头鸡（松鸡），或谐音“糊涂鸡”，传统民间鲁菜菜式，具体做法也相差较大。广泛流行于山东及其周边地区，京津，苏北，东北辽宁等地受鲁菜影响地区都有改良菜式。按烹饪方法不同，主要有鲁式松鸡，南式松鸡，铁锅黄焖鸡，川式油松鸡，大酱松鸡等。传统松鸡于明代起源于广饶县大码头镇东部（原属寿光），一直为私房菜式，烹饪也无标准，经过寿光人的不懈努力，2002年鲁式松鸡被山东省鲁菜协会收录为鲁菜样式，并以王高虎头鸡烹饪方法标准菜式。现为寿光市王高镇和广饶县大码头镇地区名菜，现被收录为寿光市民间传统饮食名录，2004年入选潍坊市非物质文化遗产，2006年入选山东省级非物质文化遗产。"
            }
        ]
    },
    {
        name:'广饶',
        title:'广饶服务区',
        imgUrl:require('@/assets/img/img_25_1.png'),
        list:[
            {
                title:'肴驴肉',
                img:require('@/assets/img/img_25_2.png'),
                content:"广饶肴驴肉是山东省东营市广饶县的地方名吃。“天上龙肉，地上驴肉”，广饶肴驴肉，历史悠久，遐迩闻名。始创于清同治十二年，曾由当地武举崔万庆推荐到北京兵部差务府，专供武士享用。广饶大地未见走驴，然桌上有驴肉飘香。广饶县地处黄河三角洲南部，小清河两岸，齐文化影响地，有孙子故园一所，内有南宋大殿，斗拱结构，号称国宝。食品简介据史料记载，广饶肴驴肉于清朝同治十二年(1873年)曾由当地武举崔万庆推荐到北京兵部差务府，专供武士享用，素有广饶“传统名吃”之盛名。 "
            }
        ]
    },
    {
        name:'青州',
        title:'青州服务区',
        imgUrl:require('@/assets/img/img_26_1.png'),
        list:[
            {
                title:'清真糗糕',
                img:require('@/assets/img/img_26_2.png'),
                content:"在山东潍坊青州市，小食店随处可见，干的、湿的，蒸的、烙的、炒的、炸的应有尽有，品类繁多。好多美食只有在青州能吃到，其中有一样就是——糗糕。糗糕是用黄米、糯米、红枣、花生仁等煮成的一种糕类食品，可以使用食用油煎制，味道更佳。一般米糕都是经过蒸制而成，而糗糕则是经过水煮和不断搅动而成，在青州方言中，人们把搅动叫做“糗”，因而这种糕也得名为糗糕。糗糕是用黍米加大枣及花生仁等煮成的，稠于粥而黏，吃起来黏而甜，有特殊的米香味。"
            }
        ]
    },
    {
        name:'淄博',
        title:'淄博服务区',
        imgUrl:require('@/assets/img/img_27_1.png'),
        list:[
            {
                title:'淄博豆腐箱',
                img:require('@/assets/img/img_27_2.png'),
                content:"豆腐箱又名山东豆腐箱，齐国豆腐箱、是山东省淄博市的传统名菜，属于鲁菜系。主要原料是豆腐，相传清乾隆帝南巡时，曾“临幸”博山，招待用膳时，上有豆腐箱这道菜，乾隆食后赞不绝口。口感细腻，浓香满口，皮韧馅嫩，回味无穷。其香其美，是语言和文字表达不出来的。<br/>豆腐箱将素荤巧妙搭配，只有亲口品尝才能感受到这道美食的无比妙趣，它给人的是色香味意形全方位的享受与快感。"
            }
        ]
    },
    {
        name:'临朐',
        title:'临朐服务区',
        imgUrl:require('@/assets/img/img_28_1.png'),
        list:[
            {
                title:'临朐全羊宴',
                img:require('@/assets/img/img_28_2.png'),
                content:"临朐全羊宴是山东临朐的传统名吃，起源于民国时代。“全羊汤”，俗称“大锅羊肉”。选用临朐青石山上三年以上的黑山羊为主料。宰杀前只饮水，不喂食，使其空腹。宰杀后，将整身肉分割为6—8块，头、蹄及整身肉先入锅，内脏肉复上，加适量山泉水和盐，水烧开后放肉，水沸时去沫，再用温火煮2—3小时熟烂出锅，剔除骨头挂凉，然后将整身肉顺丝切片，内脏肉横丝切片，一并与原羊肉汤入锅煮沸，再将适量葱丝、香菜段、胡椒面、香油、味精放汤盆中倒入锅内，“全羊汤”即成。"
            }
        ]
    },
    {
        name:'泊头',
        title:'泊头服务区',
        imgUrl:require('@/assets/img/img_29_1.png'),
        list:[
            {
                title:'流钟锅子饼',
                img:require('@/assets/img/img_29_2.png'),
                content:"【流钟锅子饼】沾化流钟锅子饼起源于明朝末年，其做法精细，工艺讲究，因食之酥而不硬、香而不腻、味鲜可口、老少皆宜而久负盛名。<br/>泊头服务区也将这一特色美食开设在了服务区内，更有各地食客慕名而来。锅子饼一定要到沾化来品尝才算地道，精粉软面，干柴文火平底锅烙制，以薄为好，似蝉翼半透明，两张对叠，中间擦油少许，入锅即鼓出锅即分。快火炒菜以饼卷之，饼皮仿佛一层薄纱，黄瓜、木耳、虾仁等卧在纱帐内清晰可见，筋道的饼皮佐以馥郁香醇的馅料，咀嚼间唇齿留香风味十足。"
            }
        ]
    },
    {
        name:'无棣',
        title:'无棣服务区',
        imgUrl:require('@/assets/img/img_30_1.png'),
        list:[
            {
                title:'无棣八大碗',
                img:require('@/assets/img/img_30_2.png'),
                content:"无棣八大碗是无棣县的传统美食，也是无棣县最具代表性的菜品之一。它以蒸的方式烹制，以五味为主要食材，包括包芙蓉鸡、松肉、藕合、卷煎、四喜丸子、小面丸子、炸虾仁和蒸梭鱼。每道菜都有其独特的寓意，如四喜丸子象征四喜临门，藕合象征阖家欢乐，小面丸子象征着儿孙满堂等等。无棣八大碗不仅在婚礼等喜庆场合备受推崇，也是家庭聚餐和亲友聚会的理想选择。它不仅味道鲜美，而且营养丰富，深受人们喜爱。"
            }
        ]
    },
    {
        name:'滨州',
        title:'滨州服务区',
        imgUrl:require('@/assets/img/img_31_1.png'),
        list:[
            {
                title:'水煎包',
                img:require('@/assets/img/img_31_2.png'),
                content:"中国美食文化源远流长，再加上我国地域宽广，幅员辽阔也形成了各个地方不同的饮食习惯和美食文化，其中小吃文化也是如数家珍。<br/>滨州东服务区位于滨州市最东部，紧邻东营市利津县，走到这里，询问当地人周边有什么美味小吃，无一不是推荐服务区所在村-南贾村水煎包。水煎包，是中国特色传统小吃，距今已有500多年的历史。口味以脆而不硬、香而不腻，未到鲜美极致。打开您的味蕾，享受舌尖上的美食。"
            }
        ]
    },
    {
        name:'邹平西',
        title:'邹平西服务区',
        imgUrl:require('@/assets/img/img_32_1.png'),
        list:[
            {
                title:'青阳炒鸡',
                img:require('@/assets/img/img_32_2.png'),
                content:" 青阳炒鸡来自历史悠久美丽富饶的滨州市邹平市青阳镇，地处邹平市西南部山区，东邻邹平城区、西邻章丘市。青阳人宴宾待客对鸡颇受青睐，当地有句俗语“无鸡不成席”。餐桌上有鸡，寓有大吉大利之意。也表示对客人的尊重。青阳人吃鸡名目繁多，也很有讲究，青阳炒鸡起源于上世纪九十年代，有二十多年的炒制历史，尤其“候八”炒鸡，历经二十余年成为当地珍品，青阳炒鸡是尊承传统工艺，“侯八炒鸡”采用散养的优质土麻鸡为原料，精心炮制而成。其色泽红润、营养丰富、味美醇香、吮指回味，成为人们餐桌上不可多得的美食佳肴。"
            }
        ]
    },
    {
        name:'文昌湖',
        title:'文昌湖服务区',
        imgUrl:require('@/assets/img/img_33_1.png'),
        list:[
            {
                title:'文昌湖鱼头炖豆腐',
                img:require('@/assets/img/img_33_2.png'),
                content:"菜品选用文昌湖野生五斤以上花鲢鱼头，富含胶质蛋白，脂肪和热量都很低，食之有健脾补气、温中暖胃、美容润肤之效；配以淄博当地卤水豆腐，由文昌湖当地厨师精心烹制而成。该菜品的独特秘方是将鱼头洗净后用大油（猪油）和葱姜煎至表面焦黄后加入热水，汤至浓白色后放入豆腐及调料，该菜品烹制完成后汤色奶白，鱼肉细嫩，味道鲜美，辅料豆腐经过炖制，口感细滑。且上菜后底部持续加温，为过往司乘人员提供暖心、舒心的体验。是文昌湖服务区主打的特色菜品之一。"
            }
        ]
    },
    {
        name:'沂源',
        title:'沂源服务区',
        imgUrl:require('@/assets/img/img_34_1.png'),
        list:[
            {
                title:'沂源特色全羊汤',
                img:require('@/assets/img/img_34_2.png'),
                content:"说起沂源名吃，“大锅全羊”一定榜上有名，因其色、香、味俱佳且有滋补功效，深受群众喜爱。<br/>“沂源全羊好吃有三宝：羊好、水好、做法好”<br/> 羊好：沂源的羊多采用纯自然放牧方式，饿食百草，渴饮山泉，肉质细嫩不腻而又营养均衡，是绝佳的有机食品。<br/>水好：沂源县被誉为“矿泉水之乡”，山间溪流、崖下山泉，可掬可饮，清洌甘甜。做法好：《左传·昭公二十年》就有“和如羹焉”的记载，说的是全部食材一起烹煮的做法，沂源“大锅全羊”便是取其意之精髓。<br/>顶尖的食材，往往只需要简单处理，一锅全羊汤，熬出了浓浓的乡情。"
            }
        ]
    },
    {
        name:'沂水',
        title:'沂水服务区',
        imgUrl:require('@/assets/img/img_35_1.png'),
        list:[
            {
                title:'白鳞鱼煎鸡蛋',
                img:require('@/assets/img/img_35_2.png'),
                content:" 白磷鱼煎鸡蛋。这是沂水人的一种独特吃法。先将咸白鳞鱼用温水略洗,晾干;再将锅内放入适量豆油,油热后放入白鳞鱼煎熟,将搅好的鸡蛋倒入锅内,使鸡蛋将鱼包裹起来,小火煎,见鸡蛋与鱼成为一体了,出锅装盘,这道菜便做成了。"
            }
        ]
    },
    {
        name:'莱芜',
        title:'京沪高速莱芜服务区',
        imgUrl:require('@/assets/img/img_36_1.png'),
        list:[
            {
                title:'雪野湖鱼头佛跳墙',
                img:require('@/assets/img/img_36_2.png'),
                content:"精选莱芜雪野湖特产花鲢鱼头，配以海参、鲍鱼、花胶、本地黑猪肉等食材精细改刀，鱼头高温煎至封皮，依次放入以上食材。配以大厨精心熬制的高汤，进行炖制。大火收汁，经过40分钟的烹制，汤底浓郁回甘，不凝不腻，突显食材本身真实的柔和与鲜味，让食客滋养胃腑，回味无穷。"
            }
        ]
    },
    {
        name:'济南东',
        title:'青银高速济南东服务区',
        imgUrl:require('@/assets/img/img_37_1.png'),
        list:[
            {
                title:'甜沫',
                img:require('@/assets/img/img_37_2.png'),
                content:"甜沫是一种以小米面为主熬煮的咸粥，济南人又俗称之为“五香甜沫”。甜沫源于豫北地区的豆沫，后流传至山东济南，成为济南的一种特色传统名点，该小吃是大众粥类美食，也是泉城二怪之一。"
            },
            {
                title:'油旋',
                img:require('@/assets/img/img_37_3.png'),
                content:"油旋，又叫“油旋回”，是山东济南特色传统名吃，外皮酥脆，内瓤柔嫩，葱香透鼻，因其形似螺旋，表面油润呈金黄色，故名油旋。油旋有圆形和椭圆形两种。更有精细者，在油旋成熟后捅一空洞，磕入一个鸡蛋，再入炉烘烤一会，鸡蛋与油旋成为一体，食之更美。"
            }
        ]
    },
    {
        name:'济南',
        title:'大东环济南服务区',
        imgUrl:require('@/assets/img/img_38_1.png'),
        list:[
            {
                title:'九转大肠',
                img:require('@/assets/img/img_38_2.png'),
                content:"九转大肠：是中国山东省济南市的传统名菜，属于山东菜系。<br/>九转大肠是以猪大肠为主料，加以葱、姜、芫荽等配料，通过煮、烧等烹饪技法制作而成，成菜后有辣觉，并且酸、甜、香、咸四味俱全，色泽红润，质地软嫩。2018年9月10日，“九转大肠”入选“中国菜”中的“山东十大经典名菜”名单"
            }
        ]
    },
    {
        name:'德州东',
        title:'德州东服务区',
        imgUrl:require('@/assets/img/img_39_1.png'),
        list:[
            {
                title:'羊肠汤',
                img:require('@/assets/img/img_39_2.png'),
                content:"德州市羊肠汤又叫清血肠，是德州历史悠久的特产小吃，民间俗称为羊肠子。纯净的羊血调上上等淀粉、小磨香油、姜汁、胡椒粉等佐料成粥状，灌入新鲜肠衣内，煮熟晾之备用;食用时将煮熟的羊肠子放进羊骨熬制的老汤内加热，在碗内割制成小段加上胡椒粉、香菜末即可。吃在口中香嫩软糯，并无腥膻之感。很多老德州喜欢吃羊肠子，尤其是寒冷的冬季，认为它具有健脾开胃、通窍散寒之功效。一碗好吃的羊肠子最大秘诀在于汤汁的调配，以汁取胜才是王道，汤汁配方是“江湖秘笈”，也是师傅们的命根，轻易不外传。据坊间传说，许多好吃的羊肠子用得都是制作扒鸡的老汤，自然别具一番风味儿。"
            }
        ]
    },
    {
        name:'德州',
        title:'德州服务区',
        imgUrl:require('@/assets/img/img_40_1.png'),
        list:[
            {
                title:'德州扒鸡',
                img:require('@/assets/img/img_40_2.png'),
                content:"德州扒鸡又称德州五香脱骨扒鸡，是著名的德州三宝（扒鸡、西瓜、金丝枣）之一。德州扒鸡是中国山东传统名吃，鲁菜经典。德州扒鸡制作技艺为国家非物质文化遗产。早在清朝乾隆年间，德州扒鸡就被列为山东贡品送入宫中供帝后及皇族们享用。五十年代，国家副主席宋庆龄从上海返京途中，曾多次在德州停车选购德州扒鸡送给毛泽东主席以示敬意。德州扒鸡因而闻名全国，远销海外，被誉为“天下第一鸡”"
            }
        ]
    },
    {
        name:'平原',
        title:'平原服务区',
        imgUrl:require('@/assets/img/img_41_1.png'),
        list:[
            {
                title:'平原老豆腐',
                img:require('@/assets/img/img_41_2.png'),
                content:"平原人老豆腐的精妙之处在于:老豆腐的卤和油，制法独特颇具功力。卤用酱油、精盐加水，放入葱、花椒、八角、茴香、丁香、桂皮、姜等十几种佐料，再加入老母鸭和一种肉丝(老者自己说忘记了什么肉丝)熬制。油用优质棉籽油在温火上熬，除去油沫杂物;数小时之后，放入葱、甜酱、花椒、茴香等佐料。另备用优质红辣椒烹制的辣椒油。配料盛碗时，放入少量鸡精。将豆腐脑用平勺片入碗内，加卤和油后即可<br/>在平原，有些居民早餐基本以老豆腐为主，既好吃又实惠，百吃不厌。或许对其他地方的人来说，平原老豆腐和其他的豆腐脑啥的没区别(这说明他没吃过)。但对平原人来说是有着极其特殊的感觉的。多少游子(包括海外华人)古稀归来的第一件事就是先喝碗平原老豆腐"
            }
        ]
    },
    {
        name:'高唐',
        title:'高唐服务区',
        imgUrl:require('@/assets/img/img_42_1.png'),
        list:[
            {
                title:'驴肉火烧',
                img:require('@/assets/img/img_42_2.png'),
                content:"驴肉火烧为一种面食，一般为死面做成（注：死面为未经发酵的面粉，经过发酵的面粉叫做活面，用活面做成的饼称为“烧饼”），将其在饼铛里烙熟后，架在灶头里烘烤，使其外焦里嫩，别具风味；趁热用刀劈开，加入热腾腾的熟驴肉，是最正宗的吃法。另有肉汤加淀粉熬制的焖子夹火烧佐食，其中以保定南部定州地区的焖子最为美味和著名。有些厨师会加入驴板肠提味，吃起来也别有一番风味。"
            }
        ]
    },
    {
        name:'夏津',
        title:'夏津服务区',
        imgUrl:require('@/assets/img/img_43_1.png'),
        list:[
            {
                title:'宋楼火烧',
                img:require('@/assets/img/img_43_2.png'),
                content:"宋楼火烧为夏津特色传统名吃，系用上等面粉加适量黄豆面为原料制成。每个折六层，层层刷涂香油，经锅烙火烤而膨为灯笼形，香酥可口，每只重量仅七钱。故又名“灯笼火烧”、“风筝火烧”。开水冲泡滑腻可口，且易于消化。若切成细丝加精肉或鸡蛋焖烩，吃起来柔软松散，别具风味。"
            }
        ]
    },
    {
        name:'齐河',
        title:'齐河服务区',
        imgUrl:require('@/assets/img/img_44_1.png'),
        list:[
            {
                title:'齐河糖醋黄河鲤鱼',
                img:require('@/assets/img/img_44_2.png'),
                content:"黄河鲤鱼又称龙门鲤，其体肥大，色艳丽，味纯正，鲜嫩肥美，是我国黄河水系最优良的鱼种，号称“鱼中之王”。肉质细嫩，营养丰富，具有利水消肿，安胎通乳，下气温补的医疗价值。黄河鲤鱼有糖醋、红烧、清蒸等多种做法，糖醋黄河鲤鱼是齐河的传统名菜，此菜香味扑鼻，外脆里嫩，且带点酸，是名菜馆中的一道佳肴。其制法是：先将黄河鲤鱼去鳞，剖腹，去内脏，洗净；然后在鱼背上切成斜形刀纹，再裹黄糊，下油锅炸至金黄色，捞起装盘，浇以事先烹制的糖醋调汁即成。“糖醋黄河鲤鱼”以黄河明珠大酒店所制的最为著名。"
            }
        ]
    },
    {
        name:'聊城',
        title:'聊城服务区',
        imgUrl:require('@/assets/img/img_45_1.png'),
        list:[
            {
                title:'魏氏熏鸡',
                img:require('@/assets/img/img_45_2.png'),
                content:"魏氏熏鸡是聊城的特色美食之一，选料精良，制作精细。熏鸡选用一年左右无病活鸡，先加工成扒鸡，再装入丁香、八角、桂皮、茴香等药物，放在锯末烟火上熏制而成。经熏制的扒鸡，水分少、皮缩裂、肉外露、无弹性、药香浓，形美肉嫩骨酥，色鲜味美，入口余香深长独特。魏氏熏鸡具有悠久的历史，经由大运河远销京津和大江南北，深受食客们的喜爱。作为聊城的特产，魏氏熏鸡是一道值得品尝的美食。"
            }
        ]
    },
    {
        name:'冠县',
        title:'冠县服务区',
        imgUrl:require('@/assets/img/img_46_1.png'),
        list:[
            {
                title:'冠县酥肉',
                img:require('@/assets/img/img_46_2.png'),
                content:"冠县酥肉是冠县及周边等地常见的一种食品，产生于山东旧时过年过节，供奉使用的食品。它多以姓氏命名，食材选料特讲究，选肥瘦适宜的猪肉或羊肉，切成若干长细条，加上适当葱姜淀粉花椒味酒等佐料，放入佐料后拌匀搅匀，进行一个小时的“养肉”。酥肉放到稍微凉一点时，再放入油中复炸一次，捞出，沥干油即可。刚炸出锅的酥肉，吃一口真是外焦里嫩，满口溢香，肉鲜味美之气荡漾在口腔之中。"
            }
        ]
    },
    {
        name:'平阴',
        title:'平阴服务区',
        imgUrl:require('@/assets/img/img_47_1.png'),
        list:[
            {
                title:'玫瑰梨丸子',
                img:require('@/assets/img/img_47_2.png'),
                content:"玫瑰梨丸子是山东平阴县的特色传统名吃之一，平阴梨丸子为正宗，口感最为纯正。梨丸子起源于清朝乾隆年间，距今已有200多年的历史。 梨丸子采用本地的大青梨为主要原料，以平阴特产的玫瑰花制成的糖馅作辅料，加上青红丝、核桃仁、芝麻、白糖精制而成。味道纯正，香甜可口，备受食者赞誉。制作好的梨丸子，其外形似毛栗子状，呈金黄色，外皮酥脆，内里软嫩，梨味浓郁，并有浓郁的玫魂香味。"
            }
        ]
    },
    {
        name:'泰安',
        title:'泰安服务区',
        imgUrl:require('@/assets/img/img_48_1.png'),
        list:[
            {
                title:'泰山油酥火烧',
                img:require('@/assets/img/img_48_2.png'),
                content:"泰山油酥火烧，是一道色香味俱全的传统小吃。因产于山东省泰安市岱岳区范镇而得名，是范镇第一名吃。在全国各地享有盛名，销量多个国家和地区。烧饼制作历史悠久，至今已有400多年的历史。最早叫“徐家烧饼”，曾奉乾隆帝旨，做烧饼，博得皇帝赞誉，御笔题写了匾额，从此名声大振。几经工艺改造，如今已是家喻户晓，深受多地消费者的喜爱。"
            }
        ]
    },
    {
        name:'泰山',
        title:'泰山服务区',
        imgUrl:require('@/assets/img/img_49_1.png'),
        list:[
            {
                title:'煎饼卷大葱',
                img:require('@/assets/img/img_49_2.png'),
                content:"原料:<br/>当地小米煎饼，大葱或小鲜葱，五香疙瘩头，芝麻盐，汶河噘嘴小鱼，黄豆酱。<br/>制作:<br/>1.大葱切段，疙瘩头制作成麻辣咸菜，芝麻炒熟撵成沫加熟盐搅拌，汶河小噘嘴鱼腌制一小时拍粉炸酥，黄豆酱加清汤香油搅拌入蒸车蒸一小时凉透。<br/>2.将四款小配菜入小碗跟煎饼和葱一起入盘摆放成型即可。"
            }
        ]
    },
    {
        name:'新泰西',
        title:'新泰西服务区',
        imgUrl:require('@/assets/img/img_50_1.png'),
        list:[
            {
                title:'松蛾炖鸡',
                img:require('@/assets/img/img_50_2.png'),
                content:"松蛾炖鸡。松蛾是一种生长在落叶松林下的野生菌类。是不能人工种植的菌类品种之一。泰山松蛾炖鸡味道鲜美，营养价值丰富。肉厚的松蛾口感醇厚，独特的菌菇香和肉嫩皮弹的散养柴鸡炖在一锅里，这就是舌尖上的泰山啊。"
            }
        ]
    },
    {
        name:'东平南',
        title:'东平南服务区',
        imgUrl:require('@/assets/img/img_51_1.png'),
        list:[
            {
                title:'赵氏糟鱼',
                img:require('@/assets/img/img_51_2.png'),
                content:"千年古县——山东东平历史悠久，迄今有记载的历史近2000年，这里历史名吃众多，其中之一是深受北方人喜欢的淡水鱼制品——赵氏糟鱼。<br/>赵氏糟鱼为东平传统名吃之一，原产地在今东平县州城街道。根据有关记载和传说，赵氏糟鱼的创制始于清康熙三十四年（公元1695年），已历三百余年。据东平县志记载，康熙三十年（公元1691年），赵氏糟鱼在当地就有相当名气。乾隆十八年（公元1753年），“扬州八怪”之一的郑板桥曾游东平湖，并到赵家（赵斌祖上赵振玉糟鱼店）糟鱼店品尝“赵氏糟鱼”。初尝之下，郑板桥赞叹不已，直呼“此味只应天上有，人间难得几回偿啊”，又咏诗一首赞美赵氏糟鱼曰“做官山东十一年，不知湖上鲫鱼鲜，今朝尝得君家味，一包糟鱼胜万钱”。"
            }
        ]
    },
    {
        name:'鄄城',
        title:'鄄城服务区',
        imgUrl:require('@/assets/img/img_52_1.png'),
        list:[
            {
                title:'清真八大碗',
                img:require('@/assets/img/img_52_2.png'),
                content:"鲁西南“八大碗”是民间厨子的行话，多指一些硬菜的套路，也就是席面的核心大菜，一般为八道，用碗装盛，各地的饮食风格，决定了八大碗的丰富，有牛羊鱼肉、炖煮烹炸，一般分为汉族传统“八大碗”，回族清真“八大碗”，两者各有风格，荤菜占了绝大多数，彰显了豪爽、大气、实在。<br/>现在的菏泽鄄城八大碗会随着人们的口味及需求而变化，其中菜品也随之替换更新。但做法依旧十分讲究，选料严谨，烹饪考究，因材施艺，炖、焖、蒸、烧、炒、烩等烹饪方法都有体现。其中炖牛肉、炖羊肉、滑肉海带、酥肉粉条、排骨炖萝卜、金针汤、丸子汤、山药炖羊肉等等这些都是菏泽八大碗的主要菜谱构成。<br/>如今我们鄄城的菜品中也保留了很多有关八大碗菜品的味道，做法也十分讲究，选料严谨，欢迎大家在闲暇之余来菏泽鄄城品尝。"
            }
        ]
    },
    {
        name:'郓城',
        title:'郓城服务区',
        imgUrl:require('@/assets/img/img_53_1.png'),
        list:[
            {
                title:'郓城黄安驴肉',
                img:require('@/assets/img/img_53_2.png'),
                content:"黄安驴肉是郓城的特色小吃之一。古语云“天上龙肉不得享，地上驴肉尽飘香”，足见人们对驴肉的钟爱。<br/> 黄安驴肉在选材上很有讲究的：年岁过大的不选，年岁过小的不选，过瘦的不选，过肥的也不选，只选用中年驴，年龄在三、五年最宜。黄安驴肉在制作上的讲究，不亚于选材，称得上独门绝技：先选配上好中草药佐料，俗称先喂药，配制好老汤，后放进锅里煮七至八个小时。而煮肉时火候的把握也非常关键，初期用大火猛烧，水沸后压火焖肉，慢火轻熬，精心烹饪而成。它色鲜味美，熟烂易嚼，吃起来有一种诱人食欲的特殊香味，久食不厌。如果您到郓城游玩，在欣赏文化和美景的同时，可千万不要忘了品尝一下这里的美食。"
            }
        ]
    },
    {
        name:'菏泽',
        title:'菏泽服务区',
        imgUrl:require('@/assets/img/img_54_1.png'),
        list:[
            {
                title:'红焖羊羔肉',
                img:require('@/assets/img/img_54_2.png'),
                content:"据《菏泽志》记载，在民国时期，沙海的马家地锅羊肉饭馆就有羊羔肉出售。正宗的羊羔肉做法独特。生炒羊羔肉的选材、用料都十分讲究。在羊羔选择上，马师傅指明:一般选择7.5公斤至10公斤重的羊羔。羊羔太小，肉嫩无味，羊羔太大，肉老不嫩。烹制青山羊羊羔肉，主要是在“爆”的基础上，兼用“焖”和“烩”的手法。大火爆炒，让香味进入肉中，使菜肴鲜嫩可口，香味四溢，使其具有地方特色和民族风味。色、香、味、形俱佳，肥而不腻，色泽棕红，肉嫩味香。烹制好的优质羊羔肉，既有鸡肉的清香，又有兔肉的软嫩。羊羔肉营养丰富，肉质鲜嫩，食之有补气滋阴、暖中补虚、开胃健力的功效。如果您到菏泽游玩，在欣赏文化和美景的同时，可千万不要忘了品尝一下这道美食。"
            }
        ]
    },
    {
        name:'曹州',
        title:'曹州服务区',
        imgUrl:require('@/assets/img/img_55_1.png'),
        list:[
            {
                title:'曹县烧牛肉',
                img:require('@/assets/img/img_55_2.png'),
                content:"曹县烧牛肉是菏泽曹县一道久负盛名的特色美食，是曹县回族人民独创，具有悠久历史的清真食品。自元朝以来，曹县烧牛肉便是民间餐桌上的一道名菜，它以鲁西南黄牛为制作原料，加各种调料和小盐加以腌透，细火烧熟后晾干，再用小磨香油烹、炸，从而具有色、香、味、美、肉质鲜嫩、香味浓郁、熟而不散的优点。若与名吃“曹县烧饼”一起食用，别有一番风味，当地有“烧饼加牛肉，越吃越有味”的说法。曹县烧牛肉不仅是酒桌筵席上的美味佳肴，也是曹县著名的特产名吃，曹州服务区所售烧牛肉有真空包装，方便携带，也是可以作为探亲访友的上等礼品。"
            }
        ]
    },
    {
        name:'东昌府',
        title:'东昌府服务区',
        imgUrl:require('@/assets/img/img_56_1.png'),
        list:[
            {
                title:'沙镇呱嗒',
                img:require('@/assets/img/img_56_2.png'),
                content:"东昌府区呱嗒是聊城市东昌府区的汉族传统名吃，是一种煎烙的馅类小食品，色泽金黄，皮酥里嫩，内外有油，馅鲜饼香，色泽金黄。东昌府区呱嗒以沙镇呱嗒最为有名，已有200多年历史。其馅料有肉馅、鸡蛋馅、肉蛋混合馅等多种，加肉、蛋馅的味更佳。沙镇呱嗒的制作技术精巧，味道鲜美，备受欢迎。"
            }
        ]
    },
    {
        name:'梁山',
        title:'梁山服务区',
        imgUrl:require('@/assets/img/img_57_1.png'),
        list:[
            {
                title:'瓦块鱼',
                img:require('@/assets/img/img_57_2.png'),
                content:" 在济宁也有这道响亮多年的名菜——瓦块鱼，食材主要选用微山湖中所产的青鱼（鲤鱼），广阔清澈的湖水出产的青鱼味道鲜美独特，肉质白嫩，也是伴随我们一同成长的家乡特色菜。其实青鱼的营养价值还是比较多的，青鱼中除含有19、5％的蛋白质和5、2％的脂肪外，还有钙、磷、铁、B1、B2和微量元素锌，都是孩子老人都所需的。红烧瓦块鱼，天门人称为“红烧木琴鱼”，瓦块鱼通常是用青鱼烹制的，因鱼块形似土瓦而得名。瓦块鱼有清蒸、炸烩两种吃法，风味各异。清蒸瓦块鱼色白、肉嫩、味鲜，焦炸灌汁瓦块鱼色黄、肉嫩、鲜美可口，都具有色、香、味、形均佳的特点。"
            }
        ]
    },
    {
        name:'济宁',
        title:'济宁服务区',
        imgUrl:require('@/assets/img/img_58_1.png'),
        list:[
            {
                title:'济宁甏肉干饭',
                img:require('@/assets/img/img_58_2.png'),
                content:"甏肉干饭是山东省济宁市的一道传统名小吃，属于鲁菜系。甏是一种盛放食物的器皿，甏肉是用甏盛放烹制的肉。现在制作一般都用锅来代替，带皮五花肉改刀成大块放入锅中，加入特制的中药材和调料卤制，再加入用豆油皮卷制的卷煎、面筋包制的肉丸、豆腐皮、海带卷、豆腐等附配品。食用时配以当地优质大米，晶莹剔透，口感清香，碗中浇汁，加入甏肉，肥而不腻，价格实惠，不仅深受当地人民的喜爱，在山东很多地区也非常受欢迎。"
            }
        ]
    },
    {
        name:'曲阜',
        title:'曲阜服务区',
        imgUrl:require('@/assets/img/img_59_1.png'),
        list:[
            {
                title:'孔府熏豆腐',
                img:require('@/assets/img/img_59_2.png'),
                content:"孔府熏豆腐，它可是我们山东特有的传统美食，也是孔府菜里最有名气的招牌菜，风味独特，口感爽滑，可以凉拌、炒、炖、煮，又可当下酒菜。“孔府熏豆腐”的制作工艺，是以白豆腐为原料，燃以松、梨、枣、苹果木锯末或谷糠，待豆腐被锯末或谷糠油熏烤至棕黄色、泛起油亮光泽即成。这种熏制而成的豆腐表面呈棕黄色，表层结实，里面细白鲜嫩，吃起来带有浓郁的木香或谷糠香味，十分独特。"
            }
        ]
    },
    {
        name:'滕州',
        title:'滕州服务区',
        imgUrl:require('@/assets/img/img_60_1.png'),
        list:[
            {
                title:'柴扎肉',
                img:require('@/assets/img/img_60_2.png'),
                content:"滕州特色地域美食，柴扎肉。因为“柴”字听起来响亮，就是用稻柴把上好的五花肉一块一块扎起来，放入大锅中炖煮至酥烂，全部使用是大锅焖烧。持续保持加热，煮好的柴扎肉，先把稻柴解开，但是不忘用嘴上面的汁水吸干，接着一口咬下去，肥膘的爽滑，精肉的酥香在嘴巴里交融。受到了来往顾客的青睐。"
            }
        ]
    },
    {
        name:'枣庄',
        title:'枣庄服务区',
        imgUrl:require('@/assets/img/img_61_1.png'),
        list:[
            {
                title:'枣庄羊肉汤',
                img:require('@/assets/img/img_61_2.png'),
                content:"枣庄羊肉汤，以其汤白,味鲜,无膻味,肉质鲜嫩,独特的风味被誉为“鲁南第一汤”。枣庄羊肉汤首先在选羊上很讲究，要选上等的山羊。在配料上也很有一套，据说烧羊肉汤都是在夜间煮制，老板要偷偷的放十几味中药和数种调料，至于加的什么，那绝对是秘密。在煮的火候上也要掌握的非常好，过了有些腻，欠了不适口，要不温不火，恰到好处。煮熟了，要分门别类放置，选最好的羊肉加上辣椒、芫荽、味精、醋等佐料就可以装盘了。喝上一口，有荡气回肠之感，喝上一碗，使人一下子精神百倍，喝上两碗，滋阴壮阳，补气通血，再喝一碗可益寿延年。"
            }
        ]
    },
    {
        name:'薛城',
        title:'薛城服务区',
        imgUrl:require('@/assets/img/img_62_1.png'),
        list:[
            {
                title:'枣庄辣子鸡',
                img:require('@/assets/img/img_62_2.png'),
                content:"枣庄辣子鸡，选取薄皮辣椒和黑腿红公鸡为主料，公鸡以在山地或瓜地放养的为佳，体重一般在一斤半左右，辣椒须是半红半紫的薄皮辣椒。锅烧热，放油，待油八成热时放入花椒，待花椒炸出香味稍稍变色时用漏勺捞出，然后放入八角、红辣椒，炸出香味后放入姜、切好的鸡块，迅速翻炒，待鸡肉变色后放醋、葱，继续翻炒，加入香叶、桂皮等调料，保证了辣子鸡的风味与营养。"
            }
        ]
    },
    {
        name:'枣庄东',
        title:'枣庄东服务区',
        imgUrl:require('@/assets/img/img_63_1.png'),
        list:[
            {
                title:'黄花牛肉面',
                img:require('@/assets/img/img_63_2.png'),
                content:"需要上等烘干黄花菜、本地黄牛精肉，熬制而成地道牛骨高汤与面进行熬制，佐以辣椒、香油、芫荽或小蒜苗。牛肉面汤鲜美，牛肉浓香，黄花菜爽脆，面条滑爽弹，美味爽口。"
            }
        ]
    },
    {
        name:'蒙阴',
        title:'蒙阴服务区',
        imgUrl:require('@/assets/img/img_64_1.png'),
        list:[
            {
                title:'红烧兔头',
                img:require('@/assets/img/img_64_2.png'),
                content:"红烧兔子头是蒙阴地方名吃，它选用上等的兔头，在注重色香味调配的前提下，遵循中医养生保健原理和现代营养学的有关原理，精选10余种蒙山纯天然中药材，经过10余道工序烧制完成。广大食客在长期的体验和感受中将其总概括为：“吃兔腮美容，吃兔脑聪明， 吃兔眼眼明”三大特点，使切身体会到“滋补养颜、健脑明目、健脾开胃、强身壮骨”的功效，不同于其他名吃美味。蒙山红烧兔子头口味麻辣咸鲜、肉味独特、色泽诱人，素有“闻闻欲食，食之思味，味浓而美，百吃不厌”的赞誉，被评为“山东地方名小吃”。"
            }
        ]
    },
    {
        name:'蒙山',
        title:'蒙山服务区',
        imgUrl:require('@/assets/img/img_65_1.png'),
        list:[
            {
                title:'蒙山炒鸡',
                img:require('@/assets/img/img_65_2.png'),
                content:"蒙山炒鸡是蒙山特色美食之一，炒鸡采用了纯正的散养走地鸡，配以由21种名贵中药材制作而成的独特的炒鸡酱料，做法独特，鸡肉超级筋道Q弹，加上一款好的花生油，吃一口满嘴都是鸡肉鲜香，味道堪称绝妙！"
            }
        ]
    },
    {
        name:'费县',
        title:'费县服务区',
        imgUrl:require('@/assets/img/img_66_1.png'),
        list:[
            {
                title:'沂蒙三丝',
                img:require('@/assets/img/img_66_2.png'),
                content:"所谓三丝，其实就是三种食材切成细丝，这三种食材也不是一成不变的，通常可以是黄瓜，土豆，青笋，白菜，牛心菜，青红椒，大葱，香菜，海带，豆芽，粉丝，豆皮等等。任选三种搭配而成。用黄瓜，豆皮，胡萝卜三种，加以青红椒配色。色泽亮丽，口感美味。"
            }
        ]
    },
    {
        name:'沂南',
        title:'沂南服务区',
        imgUrl:require('@/assets/img/img_67_1.png'),
        list:[
            {
                title:'韭菜盒子',
                img:require('@/assets/img/img_67_2.png'),
                content:"韭菜盒子，临沂部分地区将形如饺子的韭菜盒子称“摊(ta)饺子”，是一种以面粉，韭菜加海米或猪肉，以及鸡蛋为主要原料加工制作而成的食品小吃。素馅的韭菜盒子，不肥腻，口感很好，而且很有营养。<br/>韭菜盒子(韭菜合子)，是以韭菜、鸡蛋、面作为主要食材制作而成的传统美食，是中国多地非常流行的传统小吃，在有些地区也是节日食品。一般选新鲜韭菜和海米、鸡蛋为主要原料加工制作而成的食品。韭菜盒子表皮金黄酥脆，馅心韭香脆嫩，滋味优美，是适时佳点。"
            }
        ]
    },
    {
        name:'临沂',
        title:'临沂服务区',
        imgUrl:require('@/assets/img/img_68_1.png'),
        list:[
            {
                title:'沂蒙糁汤',
                img:require('@/assets/img/img_68_2.png'),
                content:"糁（sa）是临沂城的传统名吃，有着十分深厚的历史内涵和文化传承。糁的历史悠久，至今已有两千多年历史，它味辣可口，肥而不腻，营养丰富，并有祛风逐寒，健脾开胃的功效，是临沂人喜食的早点小吃。糁很早就见诸中国古籍，历史文献多有记载。糁的制作工艺考究，是一种融合了刀工、古老传统汤法、鲁菜酸辣调味的综合技艺，看似简单，实则技术含量极高，没有一定的技术积累和沉淀，很难做出一碗味道独特且正宗的糁汤。"
            }
        ]
    },
    {
        name:'济南北',
        title:'济南北服务区',
        imgUrl:require('@/assets/img/img_69_1.png'),
        list:[
            {
                title:'把子肉',
                img:require('@/assets/img/img_69_2.png'),
                content:"把子肉作为山东济南汉族名菜，属于鲁菜系。肉肥不腻，多滋多味。济南把子肉肉要肥瘦相当，一斤切成数块，用麻绳捆成一把，浸在酱油之中，猛火开锅文火慢炖，其肥而不腻、瘦而不柴，入口有醇厚的余香。把子肉讲究用酱油来烹调，不放盐。上乘的把子肉的精彩在于有肥肉的存在才能产生出肥而不腻的上佳口感。虽然是浓油酱赤熬制，但却不咸，刚好下饭。趁热连肉带汁浇在白米饭上，亦十分甘美。"
            }
        ]
    },
    {
        name:'长清',
        title:'长清服务区',
        imgUrl:require('@/assets/img/img_70_1.png'),
        list:[
            {
                title:'长清米粉',
                img:require('@/assets/img/img_70_2.png'),
                content:"米粉是手工自然发酵而成!以“小米小巷小面条十里飘香，老城老街老手艺百年传承”，其米粉的传统手工艺技术承袭自归德街道77岁老人两个百年无断代口传心授及全国主要食用米粉米线的江西、湖南、云南、贵州等地的农村老店的探访学习和交流!  小米经十天左右时间的自然发酵，会使小米中的大分子碳水化合物产生有益菌，在分解酶的作用下，产生大量对人体有益微生物菌群，增加胃肠蠕动，从而改善肠胃消化功能，小米米粉具有爽滑，劲道，易于消化，营养价值高的特点。"
            }
        ]
    },
    {
        name:'济南',
        title:'京台高速济南服务区',
        imgUrl:require('@/assets/img/img_71_1.png'),
        list:[
            {
                title:'特色炒五峰山笨鸡',
                img:require('@/assets/img/img_71_2.png'),
                content:"用料以五峰山走地笨鸡为主，辅料五峰山松鹅、粉皮、辣椒，粗菜细作，土菜巧做，突出地方特色，注重原汁原味，既有南方菜的鲜、嫩的特色，又融合了北方菜的咸、色、浓的特点，形成了独有的甜咸适中，咸中微甜的风味。一口下去，鲜嫩多汁，配上特有的五峰山松鹅，鸡肉不油不腻，松鹅鲜香嫩滑，再加上本地辣椒，独有的辣味充斥唇舌之间，吃一块要连呼几口气，以缓解辣味，让人欲罢不能！"
            }
        ]
    },
    {
        name:'宁阳',
        title:'宁阳服务区',
        imgUrl:require('@/assets/img/img_72_1.png'),
        list:[
            {
                title:'宁阳县“四八席”',
                img:require('@/assets/img/img_72_2.png'),
                content:"凭借四个盘子、八个碗，每席以八人为限，用餐具三十二件而得名的山东泰安宁阳“四八席”，席面丰盛、上菜有序、极具儒风。食客围坐八仙桌，感受着浓浓的乡饮酒礼，品的是团圆喜悦，享的是待客礼遇。<br/>宁阳县“四八席”，已有2000余年历史，宴席中的“糖醋鲤鱼”就是有名的齐鲁名菜。2021年，“宁阳四八宴席与酒礼”被列为第五批山东省级非物质文化遗产。"
            }
        ]
    },
    {
        name:'海阳',
        title:'威青高速海阳服务区',
        imgUrl:require('@/assets/img/img_73_1.png'),
        list:[
            {
                title:'清汆鲅鱼丸',
                img:require('@/assets/img/img_73_2.png'),
                content:"清汆鲅鱼丸是一道地道的胶东美食，因其鱼丸滑嫩，松软而有咬劲，鲜咸味美也被评为咱们海阳的十大特色美食之一。鲅鱼其肉质细腻、味道鲜美、含丰富蛋白质、维生素A、矿物质等营养元素备受人们喜爱，而清汆鲅鱼丸恰恰是保留了鱼肉的原汁原味。"
            }
        ]
    },
]

export function getParam(index){
    var text = param[index]
    return text
}