<template>
  <div>
     <div class="page_bg">
        <div class="page_fwq">
            <img :src="detail.imgUrl" alt="">
        </div>
        <h4>{{ detail.title }}</h4>
        <div class="tuijian">
            <p>推荐美食</p>
            <div>RECOMMENDED FOOD</div>
        </div> 
        <div class="list" v-for="i in detail.list">
            <div class="title">{{ i.title }}</div>
            <div class="content" v-html="i.content"></div>
            <img :src="i.img" alt="">
        </div>
        <!-- <div class="list">
            <div class="content">
                油旋，又叫“油旋回”，是山东济南特色传统名吃，外皮酥脆，内瓤柔嫩，葱香透鼻，因其形似螺旋，表面油润呈金黄色，故名油旋。油旋有圆形和椭圆形两种。更有精细者，在油旋成熟后捅一空洞，磕入一个鸡蛋，再入炉烘烤一会，鸡蛋与油旋成为一体，食之更美。
            </div>
            <img src="@/assets/img/ms_img2.png" alt="">
        </div> -->
    </div>
  </div>
</template>

<script>
import { getParam } from "@/utils/auth";
export default {
    name: "detail",
    data() {
    return {
        detail:null
    };
  },
  methods:{

  },
  created() {
    this.detail = getParam(this.$route.query.id) 
},
}
</script>

<style>

</style>